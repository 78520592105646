.main {
    background-color: rgb(42, 42, 42);
}

.plants-list {
    padding: 10px;
  }
  
.plant-card {
    margin: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}


.admin-word {
    color: rgb(228, 97, 97);
}